<template>
<div class="index-warp">
  <header-nav></header-nav>
  <div class="about">
    <div class="leftBox">
      <div class="title">安税师，一站式税务师共享服务平台！</div>
      <div>安税师(anshuishi.com)在深入研究劳动法、会计法与税法的基础上， 提供专业的个税、增值税与企业所得税成本减控解决方案，通过全国 税务师共享，帮助企业税负成本减控与经营业绩倍增！</div>
    </div>
    <div class="rightBox">
      <img src="../../assets/gzh.png" alt="">
      <div class="focus">扫码关注官方微信公众号</div>
    </div>
  </div>
  
<!--  尾部-->
  <public-footer></public-footer>
</div>
</template>

<script>
import headerNav from "@/components/headerNav/headerNav";
import publicFooter from "@/components/publicFooter/publicFooter"
export default {
name: "index",
  components:{
    headerNav,
    publicFooter
  },
  data(){
    return{
     
    }
  },
  methods:{
   
  },
}
</script>

<style scoped>
  .about{
    padding-top: 120px;
    width: 1200px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
  }
  .leftBox{
    padding-top: 30px;
  }
  .leftBox .title{
    font-size: 30px;
    color: #23A466;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .leftBox{
    width: 580px;
    font-size: 18px;
    color: #333;
    line-height: 32px;
    text-align: center;
  }
  .rightBox{
    padding-right: 100px;
  }
  .focus{
    font-size: 20px;
    color: #666;
    text-align: right;
    padding-right: 20px;
  }
</style>