<template>
  <div>
<!--    顶部通栏-->
<!--    <div class="public-top">-->
<!--      <div class="top-content w">-->
<!--          <div class="top-left">-->
<!--            <span>政府招商与奖励项目申报服务平台</span>-->
<!--            <span>全国服务热线：40000-51897</span>-->
<!--          </div>-->
<!--        <div class="top-right">登录/免费注册</div>-->
<!--      </div>-->
<!--    </div>-->
<!--    导航栏-->
    <div class="public-nav">
      <div class="nav-content w">
        <div class="logo">
          <img src="../../assets/public/logo.png" alt="">
        </div>
        <div class="nav">
          <a
           v-for="(item,index) in navList"
            :href="'/#'+item.url"
             @click="changeNav(item)"
             :class="currenIndex == item.index ? 'currentNav' : ''"
             :key="index">{{item.title}}</a>
        </div>
<!--        <span class="hot">全国服务热线：40000-51897</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "header",
data(){
  return{
    navList:[
      {title:'首页',index:1,url:""},
      {title:'税务难题',index:2,url:"panelOne"},
      {title:'解决方案',index:3,url:"panelTwo"},
      {title:'税筹案例',index:4,url:"panelFour"},
      {title:'我们的优势',index:5,url:"panelSix"}
    ],
    currenIndex:1
  }
},

  methods:{
    changeNav(item){
      this.currenIndex = item.index;
      localStorage.setItem('id',item.url)
    }
  }
}
</script>

<style scoped>
.public-top{
  width: 100%;
  height: 38px;
  line-height: 38px;
  background-color: #ebeef4;
}
.top-content{
  display: flex;
  justify-content: space-between;
}
.top-left span:nth-child(1){
  padding-right: 15px;
  border-right: 1px solid #999;
}
.top-left span:nth-child(2){
  padding-left: 15px;
}
.top-content span,.top-right{
  font-size: 12px;
  color: #999;
}
.public-nav{
  width: 100%;
  height: 80px;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
}
.nav-content{
  display: flex;
}
.logo{
  width: 140px;
  height: 48px;
  margin-right: 72px;
  margin-top: 16px;
}
.logo img{
  width: 100%;
  height: 100%;
}
.nav{
  display: flex;
  align-items: center;
}
.nav a{
  display: inline-block;
  height: 100%;
  line-height: 80px;
  margin-right: 30px;
  color: #666;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
.currentNav{
  color: #00964E!important;
  border-bottom: 4px solid #00964E;
}
.hot{
  font-size: 12px;
  color: #999;
  display: inline-block;
  margin-left: auto;
  height: 100%;
  line-height: 80px;
}
</style>