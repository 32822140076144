<template>
<div class="footer">
  <div class="footer-content w">
    <div class="content-left">
      <div class="item">
        <span class="nav-title" style="cursor: pointer;" @click="toPage">关于安税师</span>
          <a href="/#panelOne" @click="toIndex('panelOne')">税务难题</a>
          <a href="/#panelTwo" @click="toIndex('panelTwo')">解决方案</a>
          <a href="/#panelFour" @click="toIndex('panelFour')">税筹案例</a>
          <a href="/#panelSix" @click="toIndex('panelSix')">我们的优势</a>
      </div>
<!--      <div class="item">-->
<!--        <span class="nav-title">新手指南</span>-->
<!--        <span>新手注册</span>-->
<!--        <span>在线咨询</span>-->
<!--        <span>财税资讯</span>-->
<!--      </div>-->
<!--      <div class="item">-->
<!--        <span class="nav-title">财税服务</span>-->
<!--        <span>工商注册</span>-->
<!--        <span>代理记账</span>-->
<!--        <span>资质办理</span>-->
<!--        <span>法律服务</span>-->
<!--      </div>-->
<!--      <div class="item">-->
<!--        <span class="nav-title">招商加盟</span>-->
<!--        <span>招商加盟</span>-->
<!--        <span>供应链招商</span>-->
<!--        <span>八仙创客</span>-->
<!--        <span>商务合作</span>-->
<!--      </div>-->
    </div>
    <div class="content-right" id="contactCode">
      <img src="../../assets/index/code.png" alt="">
      <span>扫一扫，了解安税师</span>
    </div>
  </div>

  <div class="footer-last">
    <div class="last-content w">
      <div class="last-left">
        <!-- <span class="last-title">安税师合作平台</span>
        <div class="last-list">
          <div class="item">
              <div>
                <img src="../../assets/index/footerImg1.png" alt="">
              </div>
              <span>易核名</span>
          </div>
          <div class="item">
            <div>
              <img src="../../assets/index/footerImg2.png" alt="">
            </div>
            <span>节税计算器</span>
          </div>
          <div class="item">
            <div>
              <img src="../../assets/index/footerImg3.png" alt="">
            </div>
            <span>易查账</span>
          </div>
          <div class="item">
            <div>
              <img src="../../assets/index/footerImg4.png" alt="">
            </div>
            <span>发票真伪</span>
          </div>
          <div class="item">
            <div>
              <img src="../../assets/index/footerImg5.png" alt="">
            </div>
            <span>在线合同</span>
          </div>
        </div> -->
      </div>
      <div class="last-right">
        <p>全国服务热线:<span class="red">40000-51897</span></p>
        <p>安税师 | 知识产权</p>
        <p><a href="https://beian.miit.gov.cn/" target="_blank">备案号:粤ICP备2022025379号-3 </a></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "footer",
  data(){
    return{

    }
  },
  methods:{
    toPage(){
      this.$router.push({name:'about'})
    },
    toIndex(id){
      localStorage.setItem('id',id)
    }
  }
}
</script>

<style scoped>
.footer{
  background-color: #2B2E42;
}
.footer>.footer-content{
  display: flex;
  height: 204px;
  align-items: center;
  justify-content: space-between;
  /*padding-top: 50px;*/
  /*padding-bottom: 44px;*/
}
.content-left{
  display: flex;
}
.content-left>.item{
  /*width: 175px;*/
}
.content-left>.item>a{
  color: #ffffff;
  font-size: 14px;
  opacity: 0.5;
  display: inline-block;
  margin-left: 60px;
  /*margin-bottom: 30px;*/
}
.content-left>.item>.nav-title{
  color: #ffffff!important;
  opacity: 1;
  font-size: 18px;
}
.content-right{
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.content-right img{
  width: 138px;
  height: 138px;
  margin-bottom: 14px;
  border: 2px solid #fff
}
.content-right span{
  color: #ffffff;
  opacity: 0.5;
  font-size: 14px;
}
.footer-last{
  width: 100%;
  border-top: 1px solid rgba(255,255,255,0.1);
}
.last-content{
  display: flex;
  justify-content: space-between;
}
.last-left{
  display: flex;
  align-items: center;
}
.last-left .last-title{
  color: #ffffff;
  font-size: 14px;
}
.last-list{
  display: flex;
  justify-content: center;
  align-items: center;
}
.last-list .item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 30px;
}
.last-list .item >div{
  width: 58px;
  height: 58px;
  background-color: rgba(255,255,255,0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;

}
.last-list .item >div img{
  width: 25px;
  height: 25px;
}
.last-list .item > span{
  color: #ffffff;
  opacity: 0.5;
  font-size: 12px;
  display: inline-block;
  margin-top: 6px;
}
.last-right{
  color: rgba(255,255,255,0.5);
  font-size: 14px;
}
.red{
  color: #F74141!important;
  display: inline-block;
  margin-left: 10px;
  font-size: 18px;
}
</style>